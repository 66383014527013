<template>
  <div class="dashboard__container--body">
    <Loader v-if="!shiftInfo || !shiftInfo.id" />
    <div class="dashboard__container--body--col flex" v-if="shiftInfo && shiftInfo.id" style="width:100%;">
      <ShiftCard :shift="shiftInfo"  />
      <!-- <ShiftCard :shift="shiftInfo"  /> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import ShiftCard from '@/components/Shifts/ShiftCard.vue'
// import ShiftCard from '@/components/Shifts/ShiftCard.vue'

export default {
  name: 'shiftView',
  computed: {
    ...mapState(['userProfile', 'currentUser', 'shiftInfo']),
    // eventCheck() {
    //   if (this.eventInfo && !this.eventInfo.id) {
    //     this.$store.dispatch("getEventFromId", this.shiftInfo.eventId)
    //   }
    // }
    
  },
  created() {
    if (!this.shiftInfo || !this.shiftInfo.id) {
      this.$store.dispatch("getShiftFromId", this.$route.params.id)
    }
  },
  // mounted () {
  //   this.$store.dispatch("getEventFromId", this.shiftInfo.eventId)
  // },
  components: {
    Loader,
    ShiftCard
  },
  beforeDestroy() {
    this.$store.dispatch("clearShift")
  }
}
</script>